  @media(min-width: 768px) {
    .logo img {
      max-height: 50px;
    } 
  }


  @media(min-width: 992px) {
    .res-list .reward-item {
      width: calc(25% - 2px);
    }
    .nav-menu > span {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding-right:10px;
    }
    .nav-menu span a {
      font-size: 15px;
      font-weight: 400;
      color: #334958;
      text-decoration: none !important;
      margin-left: 0;
      margin-right: 0;
      width: auto;
      display: inline-block;
      text-align: center;
      padding: .5rem .9rem !important;
    }
  }



  @media (min-width: 1200px) {
       
    .logo img {
      max-height: 56px;
    }

    .bc-nav {
      width: 100%; 
    }

    

    .nav-menu {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

      

      .nav-menu .btn_signin {
        min-width: 125px;
      }

    .res-list .reward-item {
      width: calc(16.6666% - 2px);
    }
  }
@media(max-width:1200px){
  .padding-135 .nav-menu span a {
    /*font-size: 15px;*/ 
    padding: .5rem .65rem !important;
  }
}
@media(max-width:1100px) {
  
    .f_list_link2 a {
      width: 25%;
      display: inline-grid;
    }

    .db-icon-group {
      padding: 5px;
      min-width: 90px;
    }

    .db-icon-no {
      font-size: 2.5rem;
    }
  }

  @media(max-width: 992px) {
    .modal-backdrop.show {
      display:none;
    }

    .topic-padding > .col-md-12 {
      padding: 0 !important;
    }
       
      .res-list .reward-item {
        width: calc(33.333% - 2px);
      }

      /*.page-title {
        text-align: center !important;
        display: block !important;
      }*/

        .page-title img {
          display: none !important;
        }

      .db-bg {
        display: none;
      }

      .f_list_link2 a {
        margin: 5px 0px;
        padding: 5px;
        width: 50%;
        display: inline-grid;
      }

      .bc-nav {
        display: none;
        position: absolute;
        width: 240px;
        height: auto;
        background-color: rgba(255, 255, 255, 0.95);
        right: -15px;
        top: 100%;
        z-index: 99;
        box-shadow: 0 10px 15px rgba(0,0,0,.15);
        padding: 0px;
        margin-top: -1px;
      }

      .padding-135 .bc-nav {
        flex-wrap: wrap-reverse;
      }

      .bc-nav.active {
        display: flex;
      }

      .bc-nav nav {
        width: 100%;
        margin: 0 0 15px !important;
      }

        .bc-nav nav a {
          display: block;
          padding: 15px !important;
          text-align: center;
        }

          .bc-nav nav a.active {
            font-weight: bold;
            color: #222 !important;
          }

      .dash-user-group {
        position: relative;
        width: 100%;
        z-index: 2;
        justify-content: space-around !important;
        margin-bottom: 50px;
      }

      .dash-user-group-view {
        position: absolute;
        width: 100%;
        border-radius: 0;
      }



      /*.logo img {
      max-height: 42px;
    }*/
      .slide-link {
        font-size: 16px;
      }

      .btn-slide {
        margin-top: 10px;
        margin-bottom: 14px;
      }

      .slide_dot_label {
        margin: 0 auto 10px;
        display: table;
      }

      .slider-section .carousel-item img {
        min-height: 240px;
      }

      .mx-lead {
        padding-right: 0% !important;
      }

      .mini-slider .carousel-inner {
        width: 90%;
        margin: auto;
      }

      .mini-slider {
        margin: 5px auto 20px;
        max-width: 360px;
        position: relative;
      }

        .mini-slider .carousel-control-prev {
          left: -5px !important;
        }

        .mini-slider .carousel-control-next {
          right: -5px !important;
        }

      .featurette-heading {
        text-align: center;
      }

      .mini-slider .carousel-control-next, .mini-slider .carousel-control-prev {
        width: auto;
      }

      .biz-header {
        padding: 10px 0;
      }

      .newsfeed-section {
        padding: 30px 0;
      }

      .reg-form {
        margin: 0;
      }

      .nav-menu {
        display: flex;
        flex-wrap: wrap-reverse;
      }

        .nav-menu > span {
          width: 100%;
          display: block;
        }

        .nav-menu .btn {
          margin: 15px 25px 10px;
          width: 100%;
          display: block;
        }
    }

  @media(max-width: 767px) {
    article.onebox-body{
        padding-left:60px;
    }
    .aspect-image {
      width: 45px; 
    }
    .onebox-body h3{
        font-size: 1rem;
    }
    .onebox-body p {
      font-size: 13px;
    }
    /*.nav-menu > span {
        height:630px;
    }*/
    .search-row {
      margin: 0;
    }
    .tab-container {
      margin: 0 -15px;
    }
    /*.bg-content {
      background-color: #FFF;
    }*/
    .tab-nav .nav-tabs .nav-link {
      border-radius: 0;
      background-color: #EAEAEA;
      margin: 0 2px;
      color: #7B7B7B;
      flex: 1 1 auto;
    }
    .tab-nav .nav-link.active {
      color: #ffffff;
      background-color: #426688;
    }

    .f_list_link2 a {
      width: 50%;
    }

    .tab-nav .nav-tabs {
      padding-left: 1px;
    }

    .db-icon-group {
      padding: 5px;
      min-width: 90px;
      width: calc(50% - 10px);
      background: #ffffff12;
      margin: 5px;
    }

    .res-list .reward-item {
      width: calc(50% - 2px);
      font-size: 12px;
    }

    .relist-container {
      padding: 0 !important;
    }

      .relist-container .card-body {
        padding: .35rem;
      }

    .reward_image_box img {
      min-height: 84px;
    }

    .logo img {
      max-height: 45px;
    }
  }

  @media(max-width: 480px) {
    .page-title{
        font-size:20px;
    }

    .slide_dot_label {
      padding: 0 8px;
    }

    .slide-link {
      font-size: 15px;
    }

    .form-signin-cont {
      padding: 35px 15px 20px;
    }

    .m-db-header {
      margin: 5px -15px;
      height: 480px;
    }

    .bc-nav {
      width: auto;
      left: -15px;
      right: -15px;
    }

    .carousel-caption {
      left: 5%;
      right: 5%;
    }

    .btn-slide {
      font-size: 14px;
    }

    .db-icon-no {
      font-size: 2rem;
      font-weight: 300;
    }

    .db-icon-label {
      font-size: 12px;
    }

    .btn-more {
      font-size: 14px;
      padding: 7px 13px;
    }

    .modal-footer {
      padding: 0.2rem;
    }
    
  }

  @media(max-width:475px) {
    .m-db-header {
      height: auto;
    }

    .slide-link {
      font-size: 14px;
    }
  }

@media(max-width:360px) {
  .logo img {
    max-height: 36px;
  }
}
@media(max-width:600px) {
  .mb-search {
    padding: 0px;
  }
}
