/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */


 
@import "assets/css/styles.css";


@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,500&display=swap');*/



html, body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

* {
  font-family: 'Roboto', sans-serif;
}
/*Common css*/
.btn-success {
  background-color: #34BB7B;
  border-color: #34BB7B;
  color: #fff !important;
}

  .btn-success:hover {
    background-color: #2CAA6E;
    border-color: #2CAA6E;
  }

.btn-outline-success {
  border-color: #34BB7B;
  color: #34BB7B;
}

  .btn-outline-success:hover {
    background-color: #34BB7B;
  }

.text-success {
  color: #34BB7B !important;
}

.s-links a {
  font-size: 18px;
  margin-right: 15px;
}

.btn-dark {
  background-color: #426688;
  border-color: #426688;
}

  .btn-dark:hover {
    background-color: #355776;
    border-color: #355776;
  }

.text-dark {
  color: #334958;
}

.form-control:focus {
  outline: none;
  outline-offset: inherit;
  box-shadow: 0 0 1.5rem 0 rgba(0,0,0,.1);
}

.fa {
  font-size: .8rem;
}

.font-lg {
  font-size: 18px;
}

.font-md {
  font-size: 16px;
}

.font-sm {
  font-size: 14px;
}

.font-xs {
  font-size: 12px;
}

.font-xxs {
  font-size: 11px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-24 {
  font-size: 24px;
}
/**/

.logo img {
  max-height: 48px;
}

.btn {
  border-radius: 0px 0px 14px 0px;
}

.btn_signin {
  padding-left: 24px;
  padding-right: 24px;
}

.reward_community {
  padding: 50px 0;
}

.btn-link {
  font-size: 15px;
}

  .btn-link i {
    font-size: .7rem;
  }

.f-section {
  background-color: #F7F7F7;
}

.f_h5_title {
  color: #334958;
  font-size: 14px;
  margin-bottom: 20px;
}

.f_list_link li a {
  color: #426688 !important;
  font-size: 13px;
  margin-bottom: 20px;
}

.f_list_link p {
  color: #426688 !important;
  font-size: 13px;
}

.f-input-form {
  max-width: 370px;
}

.f-input input {
  font-size: .8rem;
}

.f-btn .btn {
  font-size: 12px;
}

.f-section2 {
  background-color: #334958;
}

.f_list_link2 a {
  color: #8C9FAC;
  font-size: 12px;
  margin: 5px 30px;
  padding: 5px;
}

.text-copy {
  font-size: 14px;
}

h2, h3 {
  font-weight: 300;
  color: #334958;
}

.lead-text {
  color: #426688;
  font-size: 15px;
  line-height: 1.6;
}

.mx-lead {
  padding-right: 30%;
}

.nav-menu span a {
font-size: 14px;
font-weight: 400;
color: #334958;
text-decoration: none !important;
margin-left: 10px;
margin-right: 10px;
}

  .nav-menu span a:hover {
    color: #34BB7B !important;
  }

.f-section {
  padding-top: 30px;
}


.f-title:before {
  position: absolute;
  height: 60px;
  background: #334958;
  width: 100vh;
  content: '';
  left: -100vh;
  top: 0;
}

.f-title {
  position: absolute;
  background: #334958;
  padding: 20px 70px;
  margin: 0;
  left: 0;
  top: -60px;
  display: flex;
  height: 60px;
  font-size: 20px;
  color: #FFFFFF;
  align-items: center;
  border-radius: 0 0 15px 0;
  padding-left: 15px;
}

.t-shape {
  position: absolute;
  margin: 0;
  right: -33px;
  top: 0px;
  display: flex;
  width: 0;
  height: 0;
  border-bottom: 30px solid #34BB7B;
  border-right: 33px solid transparent;
}

.slide_dot_label {
  background: #426688;
  padding: 0 20px;
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 10px;
}

.slide-link {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 15px 5px;
  color: #FFFFFF;
  border-bottom: 3px solid transparent;
  line-height: 1;
}

  .slide-link.active {
    color: #34BB7B;
    border-bottom: 3px solid #fff;
  }

.slide-leadtext p {
  max-width: 460px;
  margin: 10px auto 50px;
}

.btn-slide {
  padding: 12px 26px;
  line-height: 1.4;
  margin-bottom: 26px;
}

/*login*/

.link-forgot {
  color: #AFAFAF;
}
/**/

.page-title {
  font-weight: 300;
}

.bg-content {
  background-color: #F0F1F2;
}

.svg-lg {
  width: 36px;
}

.svg-md {
  width: 24px;
}

.svg-sm {
  width: 18px;
}

.svg-xs {
  width: 15px;
}

.dashboard-header {
  /*
	background: #101d27 url("../icon-svg/dashboard-header-bg.png") no-repeat;
	background-size: cover;
*/
  display: flex;
  min-height: 100px;
  position: relative;
}

.dashboard-header-body {
  position: absolute;
  z-index: 9;
  padding: 20px;
  width: 100%;
  left: 0;
  top: 0;
}

.media-profile-sm img {
  width: 40px;
  border-radius: 100%;
  height: auto;
  margin-right: 10px;
}

.media-profile-title {
  font-size: 15px;
  color: #FFFFFF;
}

.db-header-text {
  background-color: #426688;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 15px;
  position: relative;
  margin-top: -20px;
  height: 60px;
}


.db-icon-group {
  padding: 20px;
  display: inline-block;
  min-width: 110px;
  text-align: center;
}

.db-icon-label {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
}

.db-icon-no {
  font-weight: 200;
  font-size: 3rem;
  border-top: 1px solid #FFFFFF;
  padding: 5px 5px;
  color: #fff;
}

/*progress*/
.progress-container {
  padding: 20px;
}

  .progress-container .progress-bar {
    background-color: #34BB7B;
  }

  .progress-container .progress {
    height: 10px;
    border-radius: 5rem;
  }






/*progress*/
.link-rewards {
  text-decoration: none !important;
  color: #34BB7B;
}

  .link-rewards:hover {
    color: #FFFFFF;
  }

.coin-content {
  padding: 27px 30px 20px;
}

.coins-text {
  color: #879aa7;
  font-weight: 600;
  font-size: 15px;
}

.coins-label {
  color: #fff;
  font-size: 1.3rem;
}

  .coins-label small {
    font-weight: 600;
    font-size: 12px;
  }

.btn-topic-head {
  padding: 20px 0;
}

  .btn-topic-head .btn {
    border-radius: 0;
  }

.btn i {
  font-size: .7rem;
  color: inherit;
  margin: 0 5px;
}

.btn-light-biz {
  color: #707070;
}

.btn-newtopic {
  margin-left: 20px;
}

.db-topic-body {
  padding-top: 30px;
}

.tw-footer {
  font-weight: 500;
  font-size: .8rem;
}

.tw-tags {
  padding: 14px 0;
}

  .tw-tags label {
    padding: 7px 24px;
    color: #FFFFFF;
    background-color: #426688;
  }

.topic-widget {
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

  .topic-widget > p {
    color: #426688;
    font-weight: 300;
    font-size: 14px;
  }

.topic-aside-list {
  padding: 10px 0 20px;
}

.text-p {
  color: #426688;
  font-weight: 300;
  font-size: 14px;
}

.tw-aside-title {
  color: #707070;
}

.topic-aside-list p {
  font-weight: 300;
  color: #707070;
  margin-bottom: .5rem;
}

.tw-icon-label {
  align-content: center;
  display: flex;
}

.tw-link a {
  text-decoration: none;
  font-weight: 500;
}

  .tw-link a > i {
    font-size: .7rem;
    margin-left: 2px;
    font-weight: normal;
  }

.viewmore {
  text-decoration: underline;
}

.topic-opp-list {
  padding: 15px 0;
}

  .topic-opp-list > p {
    color: #334958;
    margin-bottom: .5rem;
    font-weight: 300;
  }

.biz-header {
  padding: 20px 0;
  position: relative;
}

/*.biz-logged {
  padding-right: 130px;
}*/

.dash-user-group {
  position: absolute;
  height: 100%;
  background-color: #344a5a;
  padding: 10px 8px;
  width: 190px;
  right: 0;
  top: 0;
  background: #344a5a; /* Old browsers */
  background: -moz-linear-gradient(top, #344a5a 0%, #426688 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #344a5a 0%,#426688 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #344a5a 0%,#426688 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#344a5a', endColorstr='#426688',GradientType=0 ); /* IE6-9 */
}

  .dash-user-group a {
    padding: 5px 10px;
    color: #FFFFFF;
    font-size: 1.2rem;
    margin:0;
  }

    .dash-user-group a:hover, .dash-user-group a.active {
      color: #34BB7B;
    }
.dash-user-group-view a:hover > span {
  color: #334958;
}
.dash-user-group-view {
  background-color: #34BB7B;
  padding: 10px;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  color: #fff;
  border-radius: 0px 0px 14px 0px;
}

/*Tab css*/
.tab-nav .nav-tabs .nav-link {
  border: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 15px;
  min-width: 80px;
  text-align: center;
  border-radius: 0;
}

.tab-nav .nav-link {
  color: #A2A2A2;
}

  .tab-nav .nav-link.active {
    color: #334958;
  }

.media_panel {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  padding: 15px 17px;
  color: #426688;
}

.media_img {
  max-width: 150px;
  display: inline-block;
  margin-right: 1rem;
}

.media_p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: .5rem;
}

.media_title {
  font-weight: 300;
  margin: 10px 0;
}

.btn-more {
  font-size: 14px;
  padding: 10px 20px;
}

.media_btn {
  text-align: center;
}

.tab-nav .nav-tabs {
  padding-left: 1px;
}

/*aside  topic widget*/
.topic-widget-aside {
  padding: 10px 0;
}

.topic-widget-title {
  font-size: 1.1rem;
}

.topic-widget-aside .tw-tags {
  padding: 5px 0;
}

  .topic-widget-aside .tw-tags .font-sm {
    font-size: 11px;
    line-height: 1;
  }

.tw-label {
  font-size: 13px;
}

/*Tab css*/
.newsfeed-section {
  padding: 60px 0;
  background: #344a5a; /* Old browsers */
  background: -moz-linear-gradient(top, #344a5a 0%, #426688 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #344a5a 0%,#426688 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #344a5a 0%,#426688 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#344a5a', endColorstr='#426688',GradientType=0 ); /* IE6-9 */
}

.nf-list .tw-opp-title {
  color: #FFFFFF;
  margin-bottom: .2rem;
}

.nf-list .nf-p {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
}

.nf-list {
  max-width: 500px;
}

  .nf-list .topic-opp-list {
    padding: 11px 0;
  }

.middle {
  vertical-align: middle;
}

#pagination ul {
  margin-left: 0;
  margin-bottom: 1rem;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  background:#fff;
}

  #pagination ul .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: default;
  }

  #pagination ul li {
    display: table-cell;
  }
#pagination li.pagination-next, #pagination .pagination-previous {
  background-color: #fff;
  border-radius: 5px;
}

#pagination .small-screen {
  display: none !important;
}

.padding-135{
    padding-right:185px;
}


/*a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}*/



.m-db-header {
  background: #334958 url("/assets/images/dashboard-header-bg@3x.png") no-repeat;
  padding: 25px 20px;
  background-size: 100%;
  height: 490px;
  margin: 5px 0px;
}

  .m-db-header .coin-content {
    padding: 20px 0px;
  }


.menubar-icon {
  display: inline-block;
  cursor: pointer;
  vertical-align:middle;
}

.bar1, .bar2, .bar3 {
  width: 24px;
  height: 3px;
  background-color: #334958;
  margin: 5px 0;
  transition: 0.25s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}

.birthDate {
  margin-right: -5px;
  margin-left: -5px;
}
  .birthDate .col-4 {
    padding-right: 5px;
    padding-left: 5px;
  }


.res-list .reward-item {
  text-align: center;
  color: black;
  margin: 1px;
  padding: 5px;
  font-weight: normal;
  background-color: #e6e6e6;
  border: 2px solid #e0ddd4;
  overflow: auto;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
}
.reward_image_box img {
  width: auto !important;
  text-align: center;
  display: block;
  margin: auto;
  max-width: 100%;
  height: auto !important;
  min-height: 100px;
}
.relist-container .card-header {
  padding: .35rem .25rem
}
.relist-container .card-header button {
  font-size: 16px;
  font-weight: 500;
  color: #454545;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  opacity: 1;
  transition: opacity .6s ease;
}
.carousel-indicators .active {
  background-color: #34BB7B;
}

/*.newhead-row > .d-flex{
    display:block!important;
}*/
.mini-slider .carousel-inner {
  width: 350px;
  margin: auto;
}

.newhead-row .title-actions {
  text-align: right !important;
}

.noti-listitem{
    padding:10px 15px;
    border-bottom:1px solid #eee;
}
.noti-listitem:last-child {
  border-bottom: 0px solid #eee;
}
.noti-icon {
  margin-right: 10px;
  width: 34px;
  text-align: center;
  color: #bfbfbf;
}
/*.noti-desc {
  line-height: 1.2;
}*/
.font-weight-300{
    font-weight:300 !important;
}

.noti-listitem:hover {
  background: #f4f7f6;
}

.notification-dp .dropdown-toggle::after{
    display: none;
}
.notification-dp .dropdown-menu.show {
  width: 360px;
  top: 100% !important;
  margin-top: 20px;
  border: 0;
  box-shadow: 0 0 20px rgba(101, 101, 101, 0.14);
  margin-right: -15px !important;
  right: 0 !important;
  left: auto !important;
  transform: inherit !important;
}
.notification-dp .dropdown-menu:before {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-bottom: .7em solid #fff;
  border-right: .7em solid transparent;
  border-top: 0;
  border-left: .7em solid transparent;
  top: -9px;
  margin: auto;
  position: absolute;
  right: 22px;
}
.jointoday {
  background: #526876 url("/assets/images/regback.jpg") no-repeat left top;
  background-size: cover;
}
