
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,500&display=swap');*/



html, body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

* {
  font-family: 'Roboto', sans-serif;
}
